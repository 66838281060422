import React from 'react'
import Layout from '../components/Layout'

const NotFoundPage = () => (
  <Layout>
    <div className="container has-text-centered">
      <h1 className="title is-1">Page Non Trouvé</h1>
    </div>
  </Layout>
)

export default NotFoundPage
